import React from 'react'
import { message, Popconfirm } from 'antd'
import { updateIsValidAddress } from '../../../../services/api/OrdersServices'

function ForceValid(props) {
    const { orderItemId, onChangeAddress } = { ...props }
    const onForceValid = async () => {
        try {
            const res = await updateIsValidAddress(orderItemId)
            const { success, message: mess, data } = { ...res }
            if (!success) {
                throw new Error(mess)
            }
            message.success(`Updated address is valid`)
            onChangeAddress(data)
        } catch (error) {
            message.error(error.message)
        }
    }
    return (
        <div className="Tooltip3 Top">
            <Popconfirm
                placement="top"
                title={`Do you confirm that you want to update this address as valid?`}
                onConfirm={onForceValid}
                okText="Yes"
                cancelText="No"
            >
                <i className="mr-3 fas fa-map-marked-alt text-success" style={{ cursor: 'pointer', fontSize: 19 }} />
            </Popconfirm>
            <div className="TooltipContent Clipboard">
                <div className="ContentInner text-center">
                    <small className="text-monospace">Force valid</small>
                </div>
            </div>
        </div>
    )
}

export default ForceValid