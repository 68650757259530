import React, {useState, useEffect} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ReturnPage from "../../shared/ReturnPage"
import OrderMeta from "./OrderMeta"
import OrderItems from "./OrderItems"
import OrderHistoriesContainer from "./histories/OrderHistoriesContainer"
import OrderBuyerDetails from "./OrderBuyerDetails"
import {getOrderSupplier} from "../../../services/api/OrdersServices"
import {Spin} from "antd"
import OrderPreference from "./OrderPreference"
import ReturnAddressContainer from './ReturnAddressContainer'

function OrderPartnerPage(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [order, setOrder] = useState({})

    const _fetchOrder = async () => {
        setLoading(true)
        setError('')

        try {
            const {fufillmentId} = props
            const response = await getOrderSupplier(fufillmentId)

            const {data, success, message} = response

            if (!success) {
                setLoading(false)
                return setError(message)
            }

            setLoading(false)
            setOrder(data)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    useEffect(() => {
        _fetchOrder()

    }, [])
    return (
        <div className="OrderPartnerPage">
            <Spin spinning={loading} tip='Get data...'>
                <DocTitleByStore title={`#${order.name} - Orders`}/>

                <ReturnPage url='/a/orders-supplier' title="Orders Supplier Manager"/>

                <OrderMeta order={order}/>

                <div className="row mt-4">
                    <div className="col-md-9">
                        {
                            !!order.order &&
                            <OrderItems items={order.items} orderId={order.order._id} orderType={order.order.order_type} packageStatus={order.package_status} fufillmentId={props.fufillmentId}/>
                        }

                        <div className="mt-3">
                            <OrderHistoriesContainer fufillmentId={props.fufillmentId}/>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <OrderPreference order={order}/>

                        <div className="mt-3">
                            {
                                !!order.order &&
                                <OrderBuyerDetails address={order.order.address} reset={_fetchOrder} />
                            }
                        </div>

                        <div className="mt-3">
                            {
                                !!order.order &&
                                <ReturnAddressContainer returnAddress={order.order.return_address}/>
                            }
                        </div>
                    </div>
                </div>
            </Spin>

            {
                error && <div className="text-danger">
                    {error}
                </div>
            }
        </div>
    )
}

export default OrderPartnerPage